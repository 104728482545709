import { ThemeAutoSwitchHOC } from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import consoleInfo from './config.json';
export {
  getApiDocsUrl,
  getContactUsSales,
  getContactUsSupport,
  getCopywritingTag,
  getIsChiefPartner,
  getIsDefaultPartner,
  getIsHarmonyPartner,
  getIsTelinPartner,
  getPartnerCode,
  getRemittanceContent,
  getThemes,
} from '@zenlayer/portal-utils';

import {
  DateListener,
  getCopyrightInfo,
  getDarkLogoPath,
  getLanguages,
  getLightLogoPath,
} from '@zenlayer/portal-utils';
import { getLocale, setLocale } from '@zenlayer/zen-intl18';
import React from 'react';

export const getFooterCopyrightInfo = getCopyrightInfo;
export const getRegistrationContent = getCopyrightInfo;

if (!__PRODUCTION__) {
  window._console_info = consoleInfo as any;
}

export const lightLogoUrl = new DateListener<'LIGHTLOGOURL', string>(
  'LIGHTLOGOURL',
  undefined,
  getLightLogoPath()
);

export const darkLogoUrl = new DateListener<'DARKLOGOURL', string>(
  'DARKLOGOURL',
  undefined,
  getDarkLogoPath()
);

export const HasTextLogo = (props?: React.ImgHTMLAttributes<any>) => {
  const lightUrl = lightLogoUrl.useValueListener();
  const darkUrl = darkLogoUrl.useValueListener();

  return (
    <ThemeAutoSwitchHOC
      dark={<img {...props} src={darkUrl} />}
      light={<img {...props} src={lightUrl} />}
    />
  );
};

export const languagesDate = new DateListener(
  'LANGUAGES',
  undefined,
  getLanguages()
);

export const useLanguages = () => {
  const languages = languagesDate.useValueListener();
  React.useEffect(() => {
    if (!languages.includes(getLocale())) {
      setLocale(languages[0]);
    }
  }, [languages]);
  return {
    languages,
    showLanguageButton: languages.length > 1,
  };
};
